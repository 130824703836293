import { RouteRecordRaw } from 'vue-router';
import LoginPage from '@web/pages/LoginPage.vue';
import NotFoundPage from '@web/pages/404Page.vue';
import { toNonNullable } from '@shared/utils/collections';

const routes: RouteRecordRaw[] = toNonNullable([
  // {
  //   name: 'home',
  //   path: '/',
  //   redirect: '/login',
  // },
  {
    name: 'login',
    path: '/login',
    component: LoginPage,
  },
  {
    name: 'signup',
    path: '/signup',
    component: () => import('../pages/SignupPage.vue'),
  },
  {
    name: 'reconfirm',
    path: '/reconfirm',
    component: () => import('../pages/ReconfirmEmailPage.vue'),
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: () => import('../pages/ForgotPasswordPage.vue'),
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    component: () => import('../pages/ResetPasswordPage.vue'),
  },
  {
    name: 'onboarding',
    path: '/onboard',
    component: () => import('../pages/OnboardingPage.vue'),
  },
  {
    name: 'invitation-accept',
    path: '/invitation/accept',
    component: () => import('../pages/AcceptInvitationPage.vue'),
  },
  {
    name: 'oauth-authorize',
    path: '/oauth/authorize',
    component: () => import('../pages/OauthAuthorizePage.vue'),
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: NotFoundPage,
  },
]);

export default routes;
