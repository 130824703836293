import { createApp } from 'vue';
import { createHead } from '@unhead/vue';
import { createRouter, createWebHistory } from 'vue-router';
import '@web/styles/tailwind.css';
import App from '@web/AppAuth.vue';
import authRoutes from '@web/routes/authRoutes';
import { registerComponents } from '@web/globals';
import '@web/monitoring';
import 'vite/modulepreload-polyfill';

const app = createApp(App);
const head = createHead();

const router = createRouter({
  routes: authRoutes,
  history: createWebHistory(),
});

app.use(head);
app.use(router);
app.use(registerComponents);

window.onload = () => {
  app.mount('#app');
};
