<template>
  <div class="app bg-white flex flex-col">
    <router-view v-slot="{ Component }">
      <transition name="page" mode="out-in" appear>
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAffiliateTracking } from '@web/features/tracking';

export default defineComponent({
  name: 'LoginApp',
  setup() {
    useAffiliateTracking();
  },
});
</script>

<style lang="postcss">
body {
  @apply select-none;
}

.app {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  @apply h-screen w-screen overflow-hidden min-h-screen justify-center py-12 px-4 bg-gray-50 bg-repeat;
  background-image: url('@shared/assets/img/dots-pattern.svg');
  background-size: 200px 200px;

  @screen desktop-sm {
    @apply p-0;
  }
}
</style>
