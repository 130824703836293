<template>
  <div
    class="rounded-md p-4 whitespace-nowrap inline-flex select-none transition-opacity duration-300"
    :class="{
      'opacity-0 ': !body,
      'bg-green-50': type === 'success',
      'bg-red-50': type === 'error',
      'bg-yellow-50': type === 'warning',
    }"
  >
    <div class="flex">
      <component
        :is="icon"
        class="h-5 w-5"
        :class="{
          'text-green-400': type === 'success',
          'text-red-400': type === 'error',
          'text-yellow-400': type === 'warning',
        }"
        aria-hidden="true"
      />

      <p
        class="ml-3 text-sm font-medium"
        :class="{
          'text-green-800': type === 'success',
          'text-red-800': type === 'error',
          'text-yellow-800': type === 'warning',
        }"
      >
        {{ body || '_' }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type Component, computed, markRaw } from 'vue';
import { useFlashMessage } from '@web/features/flashMessages';
import PhWarningCircleFill from '~icons/ph/warning-circle-fill';
import PhCheckCircleFill from '~icons/ph/check-circle-fill';

const { message } = useFlashMessage();

const type = computed(() => {
  return message.value?.type || 'success';
});

const iconMap: Record<(typeof type)['value'], Component> = {
  error: markRaw(PhWarningCircleFill),
  success: markRaw(PhCheckCircleFill),
  warning: markRaw(PhWarningCircleFill),
};

const icon = computed(() => {
  return iconMap[type.value];
});

const body = computed(() => {
  return message.value?.body || '';
});
</script>
